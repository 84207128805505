import {apiUrl} from "./EpipremnumHomeAutomationService";
import {apiUtils} from "./ApiUtils";
import {IActuatorsModeModel} from "./IActuatorsModeModel";
import {boolToStatus} from "./Esp32Constants";


class CurrentActuatorsModeService {
    async readState(deviceId: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(
            `${apiUrl}Esp32ActuatorsModeCurrentState?esp32DeviceId=${deviceId}`, requestOptions);
        return await response.json() as IActuatorsModeModel;
    }

    async postState(deviceId: string, actuatorName: string, manualMode: boolean, status: boolean) {

        const requestOptions = apiUtils.getBaseRequest('POST')
        let params = `&${actuatorName}.IsUpdatingManualMode=true`
        params += `&${actuatorName}.ManualMode=${boolToStatus(manualMode)}`
        params += `&${actuatorName}.IsUpdatingManualModeStatus=true`
        params += `&${actuatorName}.ManualModeStatus=${boolToStatus(status)}`
        let response = await fetch(
            `${apiUrl}Esp32ActuatorsModeCurrentState?esp32DeviceId=${deviceId}${params}`, requestOptions);
        return await response.json() as IActuatorsModeModel;
    }
}


export const currentActuatorsModeService = new CurrentActuatorsModeService()