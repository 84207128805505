import React, {FC, useState} from "react";
import {CircularProgress, FormControlLabel, Grid, LinearProgress, Paper, Switch, Typography} from "@mui/material";
import {elevation, margin, padding} from "../../resources/styles";
import {Strings} from "../../resources/Strings";

interface ActuatorManualModeDataTemplateProps {
    actuatorLabel: string,
    actuatorName: string,
    manualMode: boolean,
    status: boolean,
    isLoading: boolean,
    onChange: (actuator: string, manualMode: boolean, status: boolean) => void,
}

export const ActuatorManualModeDataTemplate: FC<ActuatorManualModeDataTemplateProps> = (props) => {

    const [isManualMode, setIsManualMode]
        = useState<boolean>(props.manualMode);

    const [isManualModeStatus, setIsManualModeStatus]
        = useState<boolean>(props.status);

    const onChangeCallback = (manualMode: boolean, status: boolean) => {
        props.onChange(props.actuatorName, manualMode, status)
    }


    return <>
        <Paper elevation={elevation}>


            <Grid container direction='column' margin={margin} padding={padding}>

                <Grid item xs={3}>
                    <Typography fontWeight='bold' variant='h6'>{props.actuatorLabel}</Typography>
                </Grid>

                {!props.isLoading && <>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Switch
                                checked={isManualMode}
                                onClick={async () => {
                                    await setIsManualMode(!isManualMode)
                                    onChangeCallback(!isManualMode, isManualModeStatus)
                                }}
                            />} label={Strings.ManualMode}/>
                    </Grid>
                    <Grid item  xs={3}>
                        <FormControlLabel
                            control={<Switch
                                disabled={!isManualMode}
                                checked={isManualModeStatus}
                                onChange={async () => {
                                    await setIsManualModeStatus(!isManualModeStatus)
                                    onChangeCallback(isManualMode, !isManualModeStatus)
                                }}
                            />} label={Strings.Status}/>
                    </Grid>

                </>}



                {props.isLoading && <>
                    <Grid item xs={3}>
                        <CircularProgress />
                    </Grid>
                </>}
            </Grid>

        </Paper>
    </>
}


