class ApiUtils {
    getBaseRequest(method: string) {
        let requestOptions: RequestInit = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        };
        return requestOptions
    }


    async getBase64Image(blob: Blob): Promise<string> {
        let reader = new FileReader()
        await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        return reader.result! as string
    }

    mongoIdToDate(id: string): Date {
        const timeStamp = parseInt(id.substring(0, 8), 16) * 1000
        return new Date(timeStamp)
    }
}

export const apiUtils = new ApiUtils()