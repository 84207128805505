export const OnStatus = "on"
export const OffStatus = "off"


export function boolToStatus(status: boolean) {
    if (status)
        return OnStatus;
    else
        return OffStatus
}

export function statusToBool(status: string) {
    if (status === OnStatus)
        return true;
    else if (status === OffStatus)
        return false
    else
        throw new Error(`Invalid Status ${status}`)
}