import React, {FC, useEffect, useState} from "react";
import {ICurrentState} from "../../services/ICurrentState";
import {Box, Grid, Paper, Typography} from "@mui/material";
import {elevation, margin, spacing} from "../../resources/styles";
import {Strings} from "../../resources/Strings";
import moment from "moment";


export interface CurrentStateComponentProps {
    currentState: ICurrentState
}

export const CurrentStateComponent: FC<CurrentStateComponentProps> = (props) => {
    return <Paper elevation={elevation}>
        <Box margin={margin}>
            <Grid container alignItems='center' alignContent='space-between' direction='row' spacing={spacing}>
                <Grid item xs='auto'>
                    <Typography fontWeight='bold' flexWrap='wrap'>{props.currentState.device.name}</Typography>
                    <Typography flexWrap='wrap'>{props.currentState.device.id}</Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Typography fontWeight='bold'>{Strings.Date}</Typography>
                    <Typography>{moment(props.currentState.measurements.logDate).toDate().toLocaleString()}</Typography>
                </Grid>


                {props.currentState.measurements.temperatureDegrees &&
                    <Grid item xs='auto'>
                        <Typography fontWeight='bold'>{Strings.Temperature}</Typography>
                        <Typography>{props.currentState.measurements.temperatureDegrees?.toFixed(2)} °C</Typography>
                    </Grid>
                }

                {props.currentState.measurements.humidityPercentage &&
                    <Grid item xs='auto'>
                        <Typography fontWeight='bold'>{Strings.Humidity}</Typography>
                        <Typography>{props.currentState.measurements.humidityPercentage?.toFixed(2)} %</Typography>
                    </Grid>
                }


                {props.currentState.measurements.waterTemperatureDegrees &&
                    <Grid item xs='auto'>
                        <Typography fontWeight='bold'>{Strings.WaterTemperature}</Typography>
                        <Typography>{props.currentState.measurements.waterTemperatureDegrees?.toFixed(2)} °C</Typography>
                    </Grid>
                }


                {props.currentState.measurements.luminosityLux &&
                    <Grid item xs='auto'>
                        <Typography fontWeight='bold'>{Strings.Luminosity}</Typography>
                        <Typography>{props.currentState.measurements.luminosityLux?.toFixed(2)} lux</Typography>
                    </Grid>
                }


                <Grid item xs='auto'>
                    <Typography fontWeight='bold'>{Strings.Light}</Typography>
                    <Typography>{props.currentState.actuators.lightStatus}</Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Typography fontWeight='bold'>{Strings.Fan}</Typography>
                    <Typography>{props.currentState.actuators.fanStatus}</Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Typography fontWeight='bold'>{Strings.Heater}</Typography>
                    <Typography>{props.currentState.actuators.heaterStatus}</Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Typography fontWeight='bold'>{Strings.Humidifier}</Typography>
                    <Typography>{props.currentState.actuators.humidifierStatus}</Typography>
                </Grid>

            </Grid>
        </Box>
    </Paper>
}