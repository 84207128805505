export class Strings {
    static Temperature: string = "Temperatura"
    static Humidity: string = "Humedad"
    static WaterTemperature: string = "Temperatura agua"
    static WaterHumidity: string = "Humedad sistema"
    static Luminosity: string = "Luminosidad"
    static Date: string = "Fecha"
    static Light: string = "Luz"
    static Fan: string = "Ventilador"
    static Heater: string = "Calefacción"
    static Humidifier: string = "Humificador"
    static ManualMode: string = "Modo Manual"
    static Status: string = "Status"
    static AirPump: string = "Bomba de Aire"
}
