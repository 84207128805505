import React from 'react';
import './App.css';
import {CurrentEsp32CameraImageComponent} from "./components/currentEsp32CameraImage/currentEsp32CameraImageComponent";
import {Box} from "@mui/material";
import {BoxProps} from "./resources/styles";

function App() {
    return <Box {...BoxProps}>
        <CurrentEsp32CameraImageComponent/>
    </Box>
}

export default App;
