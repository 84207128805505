/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {ActuatorManualModeDataTemplate} from "./actuatorManualModeDataTemplate";
import {currentActuatorsModeService} from "../../services/CurrentActuatorsModeService";
import {statusToBool} from "../../services/Esp32Constants";
import {Strings} from "../../resources/Strings";
import {Grid} from "@mui/material";
import {spacing} from "../../resources/styles";

interface IActuatorsConfiguration {
    actuator: string,
    manualMode: boolean,
    status: boolean,
    actuatorLabel: string
}

export const ActuatorManualModeCollectionDataTemplate = () => {

    const [actuators, setActuators]
        = useState<IActuatorsConfiguration[]>()
    const [deviceId] = useState("64bb519d6138b087ce1e42b1")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        loadDataAsync()
    }, [])

    const loadDataAsync = async () => {
        const actuatorsMode = await currentActuatorsModeService.readState(deviceId)
        const lightActuator: IActuatorsConfiguration = {
            actuatorLabel: Strings.Light,
            actuator: "light",
            manualMode: statusToBool(actuatorsMode.lightManualMode),
            status: statusToBool(actuatorsMode.lightManualModeStatus)
        }
        const humidifierActuator: IActuatorsConfiguration = {
            actuatorLabel: Strings.Humidifier,
            actuator: "humidifier",
            manualMode: statusToBool(actuatorsMode.humidifierManualMode),
            status: statusToBool(actuatorsMode.humidifierManualModeStatus)
        }
        const heaterActuator: IActuatorsConfiguration = {
            actuatorLabel: Strings.Heater,
            actuator: "heater",
            manualMode: statusToBool(actuatorsMode.heaterManualMode),
            status: statusToBool(actuatorsMode.heaterManualModeStatus)
        }
        const airPumpActuator: IActuatorsConfiguration = {
            actuatorLabel: Strings.AirPump,
            actuator: "airPump",
            manualMode: statusToBool(actuatorsMode.airPumpManualMode),
            status: statusToBool(actuatorsMode.airPumpManualModeStatus)
        }
        const fanActuator: IActuatorsConfiguration = {
            actuatorLabel: Strings.Fan,
            actuator: "fan",
            manualMode: statusToBool(actuatorsMode.fanManualMode),
            status: statusToBool(actuatorsMode.fanManualModeStatus)
        }
        setActuators([lightActuator, humidifierActuator,
            heaterActuator, airPumpActuator, fanActuator])
    }

    const onChangeModeCallback = async (actuator: string, manualMode: boolean, status: boolean) => {
        try {
            setIsLoading(true)
            await currentActuatorsModeService.postState(deviceId, actuator, manualMode, status)
        } finally {
            setIsLoading(false)
        }
    };
    return <>
        <Grid container spacing={spacing}>
            {actuators?.map(actuatorConfiguration =>
                <Grid item md={3} xs={12}>
                    <ActuatorManualModeDataTemplate key={actuatorConfiguration.actuator}
                                                    actuatorName={actuatorConfiguration.actuator}
                                                    manualMode={actuatorConfiguration.manualMode}
                                                    status={actuatorConfiguration.status}
                                                    onChange={onChangeModeCallback}
                                                    actuatorLabel={actuatorConfiguration.actuatorLabel}
                                                    isLoading={isLoading}/>
                </Grid>
            )}
        </Grid>
    </>
}