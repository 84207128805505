import {IDoomsdayStatus} from "./IDoomsdayStatus";
import {IEsp32CameraCurrentImageModel} from "./IEsp32CameraCurrentImageModel";

export const apiUrl = "https://staging-homeautomation.epipremnum.cl/"
class EpipremnumHomeAutomationService {


    async updateDoomsdayDevice(newStatus: IDoomsdayStatus) {
        let requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(newStatus)
        };
        let response = await fetch(`${apiUrl}DoomsdayCurrentState`, requestOptions);
        return await response.json() as IDoomsdayStatus;
    }

    async getEsp32CameraCurrentImageBase64(deviceId:string){

        let requestOptions: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        };
        let response = await fetch(`${apiUrl}Esp32CameraImageCurrentState?esp32DeviceId=${deviceId}`,
            requestOptions);
        return await response.json() as IEsp32CameraCurrentImageModel;
    }
}

export const epipremnumHomeAutomationService = new EpipremnumHomeAutomationService();

