import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {epipremnumHomeAutomationService} from "../../services/EpipremnumHomeAutomationService";
import moment from "moment";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {ICurrentState} from "../../services/ICurrentState";
import {CurrentStateComponent} from "./currentStateComponent";
import {spacing} from "../../resources/styles";
import {ActuatorManualModeCollectionDataTemplate} from "../dataTemplate/actuatorManualModeCollectionDataTemplate";

export const CurrentEsp32CameraImageComponent = () => {
    const [imageBase64, setImageBase64] = useState<string>()
    const [currentDate, setCurrentDate] = useState<string>()


    const [currentStates, setCurrentStates] = useState<ICurrentState[]>([])

    const {sendMessage, lastMessage, readyState}
        = useWebSocket("wss://staging-homeautomation.epipremnum.cl/ws/client", {
        shouldReconnect: (closeEvent) => true

    })

    useEffect(() => {
        console.log(readyState);
        if (readyState === ReadyState.OPEN) {
            updateCallback()
        }
    }, [readyState])

    useEffect(() => {
        if (lastMessage) {
            let json = JSON.parse(lastMessage.data)

            if (json["type"] === "command" && json["command"] === "get_status") {
                let currentStates = json["items"] as ICurrentState[]
                setCurrentStates(currentStates);
            }
        }
    }, [lastMessage])


    useEffect(() => {
        epipremnumHomeAutomationService.getEsp32CameraCurrentImageBase64("64ab8db86270f4e1631c59d3")
            .then(value => {
                if (value.currentImageBase64)
                    setImageBase64(value.currentImageBase64)

                if (value.logDate)
                    setCurrentDate(moment(value.logDate).toLocaleString())
            })
    })


    const updateCallback = () => {
        let input = {
            type: "command",
            command: "get_status"
        };
        let json = JSON.stringify(input);
        sendMessage(json);

    }

    return <>
        {currentDate && <>
            <Typography>{currentDate}</Typography>
        </>}


        <Button onClick={updateCallback} disabled={ReadyState.OPEN !== readyState}
                variant='contained'>Refresh</Button>

        {currentStates.length > 0 && <>
            <Grid container alignItems='flex-start' direction='row' spacing={spacing}>
                {currentStates.map(currentState => {
                    return <Grid item lg={6} xs={12} key={currentState.device.id}>
                        <CurrentStateComponent currentState={currentState}/>
                    </Grid>
                })}
            </Grid>
        </>}

        <ActuatorManualModeCollectionDataTemplate/>
    </>
}

