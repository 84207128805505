import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from "@mui/material";


export const themeOptions = {
    palette: {
        primary: {
            main: '#282c2f',
        },
        secondary: {
            main: '#eaeaea',
        },
        error: {
            main: '#e60606',
        },
        warning: {
            main: '#e0a404',
        },
        info: {
            main: '#839081',
        },
        success: {
            // main: '#00968e',
            main: '#007072',
        },
        text: {
            primary: 'rgba(40,44,47,0.87)',
            secondary: 'rgba(40,44,47,0.54)',
            disabled: 'rgba(40,44,47,0.38)',
            hint: 'rgba(40,44,47,0.38)',
        },
        divider: 'rgba(67,67,67,0.12)',
    },
    typography: {
        fontFamily: 'Sorts Mill Goudy',
        allVariants: {
            color: 'rgba(40,44,47,0.87)',
        }
    },
    props: {
        MuiButton: {
            // size: 'large',
            dense: true
        },
        MuiButtonGroup: {
            size: 'small',
        },
        MuiCheckbox: {
            size: 'small',
        },
        MuiFab: {
            size: 'small',
        },
        MuiFormControl: {
            margin: 'dense',
            size: 'small',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiRadio: {
            size: 'small',
        },
        MuiSwitch: {
            size: 'small',
        },
        MuiTextField: {
            margin: 'dense',
            size: 'small',
        },
    },
};

export const theme = createTheme(themeOptions)


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
